<template>
  <v-container>
    <v-row v-if="pageIsLoading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Getting data . . .
        </h3>
      </v-col>
    </v-row>

    <v-alert
      v-show="formIsSubmitted"
      transition="fade-transition"
      style="z-index: 1; position: fixed; right: 15px; min-width: 300px"
      color="success"
      type="success"
    >
      Successfully submitted!
    </v-alert>

    <v-alert
      v-show="formIsError"
      transition="fade-transition"
      style="z-index: 1; position: fixed; right: 15px; min-width: 300px"
      color="error"
      type="error"
    >
      An error occurred!
    </v-alert>

    <v-row v-if="!pageIsLoading" justify="center">
      <v-col xs="12" xl="8">
        <v-row>
          <v-col>
            <h1>Create a new stage</h1>

            <p class="grey--text">
              Identify the need for a new stage: Determine if a new stage is
              needed based on the process flow and the current status of tasks
              on the board. This could include a bottleneck in the workflow, a
              new phase in the project, or a change in the process.
            </p>
          </v-col>
        </v-row>

        <v-form v-model="formIsValid" @submit.prevent="submit">
          <v-row>
            <v-col>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="stageName"
                    :rules="[rules.required]"
                    label="Stage Name"
                    hide-details="auto"
                    outlined
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-textarea
                    v-model="description"
                    label="Description (optional)"
                    hide-details="auto"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-card
                v-for="(objectiveField, index) in sortedObjectiveFields"
                :key="index"
                class="mb-5 rounded-lg"
              >
                <v-card-title>
                  {{
                    objectiveField.subject
                      ? objectiveField.subject
                      : `New Objective`
                  }}

                  <v-spacer />

                  <v-icon
                    @click="
                      kbn_stg_objs.splice(
                        kbn_stg_objs.findIndex(
                          (el) => el.order === objectiveField.order
                        ),
                        1
                      )
                    "
                  >
                    mdi-close
                  </v-icon>
                </v-card-title>

                <v-divider />

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-select
                          v-model="objectiveField.type"
                          :rules="[rules.required]"
                          :items="['Internal Step', 'Service Ticket']"
                          label="Select Type"
                        />
                      </v-col>

                      <v-col>
                        <v-select
                          v-if="objectiveField.type === 'Service Ticket'"
                          v-model="objectiveField.service_ticket_type"
                          :rules="[rules.required]"
                          :items="[
                            'Payout Request',
                            'Balance Request',
                            'Payment Amount Change',
                            'Payment Date Change',
                            'Payment Collection',
                            'Property Taxes',
                            'Insurance',
                            'Demand Letter',
                            'Foreclosure',
                            'Other',
                          ]"
                          label="Select Service Ticket Type"
                        />
                      </v-col>

                      <v-col>
                        <v-text-field
                          v-if="
                            objectiveField.service_ticket_type === 'Other' &&
                            objectiveField.type === 'Service Ticket'
                          "
                          v-model="objectiveField.specified_service_ticket_type"
                          :rules="[rules.required]"
                          hide-details="auto"
                          label="Please specify service ticket type"
                          outlined
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="objectiveField.subject"
                              :rules="[rules.required]"
                              hide-details="auto"
                              label="Subject"
                              dense
                              outlined
                            />
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="objectiveField.order"
                              :rules="[rules.required]"
                              hide-details="auto"
                              label="Order"
                              type="number"
                              dense
                              outlined
                            />
                          </v-col>

                          <v-col>
                            <v-text-field
                              v-model="objectiveField.day_limit"
                              :rules="[rules.required]"
                              hide-details="auto"
                              label="Day Limit"
                              type="number"
                              dense
                              outlined
                            />
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col>
                        <v-textarea
                          v-model="objectiveField.description"
                          hide-details="auto"
                          label="Description"
                          auto-grow
                          dense
                          outlined
                        />
                      </v-col>
                    </v-row>

                    <v-card
                      v-for="(task, index) in objectiveField.kbn_stg_tsks"
                      :key="index"
                      class="my-5 rounded-lg"
                      style="background-color: #111"
                    >
                      <v-card-title>
                        {{ task.subject ? task.subject : `New Task` }}

                        <v-spacer />

                        <v-icon
                          @click="objectiveField.kbn_stg_tsks.splice(index, 1)"
                        >
                          mdi-close
                        </v-icon>
                      </v-card-title>

                      <v-divider />

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col>
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    v-model="task.subject"
                                    :rules="[rules.required]"
                                    hide-details="auto"
                                    label="Subject"
                                    dense
                                    outlined
                                  />
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col>
                                  <v-text-field
                                    v-model="task.order"
                                    :rules="[rules.required]"
                                    hide-details="auto"
                                    label="Order"
                                    type="number"
                                    dense
                                    outlined
                                  />
                                </v-col>

                                <v-col>
                                  <v-text-field
                                    v-model="task.day_limit"
                                    :rules="[rules.required]"
                                    hide-details="auto"
                                    label="Day Limit"
                                    type="number"
                                    dense
                                    outlined
                                  />
                                </v-col>
                              </v-row>
                            </v-col>

                            <v-col>
                              <v-textarea
                                v-model="task.description"
                                hide-details="auto"
                                label="Description"
                                auto-grow
                                dense
                                outlined
                              />
                            </v-col>
                          </v-row>

                          <v-card
                            v-for="(helper, index) in task.kbn_stg_hlps"
                            :key="index"
                            class="mb-5 rounded-lg"
                          >
                            <v-card-title>
                              helper No.
                              {{ index + 1 }}

                              <v-spacer />

                              <v-icon
                                @click="task.kbn_stg_hlps.splice(index, 1)"
                              >
                                mdi-close
                              </v-icon>
                            </v-card-title>

                            <v-divider />

                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col>
                                    <v-text-field
                                      v-model="helper.subject"
                                      :rules="[rules.required]"
                                      hide-details="auto"
                                      label="Subject"
                                      outlined
                                    />

                                    <v-select
                                      v-model="helper.component"
                                      :items="components"
                                      class="mt-9"
                                      hide-details="auto"
                                      label="Component"
                                      outlined
                                    />
                                  </v-col>

                                  <v-col>
                                    <v-textarea
                                      v-model="helper.description"
                                      hide-details="auto"
                                      label="Description"
                                      outlined
                                    />
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>
                          </v-card>
                        </v-container>

                        <v-divider />
                        <v-card-actions>
                          <v-row>
                            <v-col>
                              <v-btn
                                :disabled="
                                  objectiveField.subject === '' ||
                                  task.subject === ''
                                "
                                @click="
                                  addHelper(
                                    objectiveField.subject,
                                    task.subject
                                  )
                                "
                              >
                                <v-icon class="mr-2 text-button">
                                  mdi-plus
                                </v-icon>
                                add helper
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-actions>
                      </v-card-text>
                    </v-card>
                  </v-container>

                  <v-divider />
                  <v-card-actions>
                    <v-row>
                      <v-col>
                        <v-btn
                          :disabled="objectiveField.subject === ''"
                          @click="addTask(index)"
                        >
                          <v-icon class="mr-2 text-button"> mdi-plus </v-icon>
                          add task
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn :disabled="stageName === ''" @click="addObjective">
                <v-icon class="mr-2 text-button"> mdi-plus </v-icon>
                add objective
              </v-btn>

              <v-btn
                :disabled="stageName === ''"
                class="ml-4"
                @click="template_dialog = true"
              >
                objective templates</v-btn
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn
                :disabled="!formIsValid || formIsSubmitting"
                type="submit"
                color="primary"
                class="mr-4"
              >
                <v-progress-circular
                  v-show="formIsSubmitting"
                  class="mr-2"
                  color="primary"
                  indeterminate
                />
                Submit
              </v-btn>

              <v-btn @click="$router.go(-1)"> Cancel </v-btn>
            </v-col>
          </v-row>

          <v-dialog
            v-model="template_dialog"
            max-width="900"
            hide-overlay
            transition="dialog-transition"
            persistent
          >
            <v-card>
              <v-toolbar flat>
                <v-toolbar-title> Objective templates </v-toolbar-title>

                <v-spacer></v-spacer>
                <v-btn title="close" @click="template_dialog = false" icon>
                  <v-icon color="red">mdi-close</v-icon>
                </v-btn>
              </v-toolbar>

              <v-card-text class="mt-5">
                <v-row>
                  <v-col>
                    Filter by type:
                    <v-select
                      v-model="filtered_type"
                      :items="['Service Ticket', 'Internal Step']"
                      label="Select Type"
                      dense
                      outlined
                    />
                  </v-col>

                  <v-col v-if="filtered_type === 'Service Ticket'">
                    Filter by service ticket type:
                    <v-select
                      v-model="filtered_service_ticket_type"
                      :items="[
                        'Payout Request',
                        'Balance Request',
                        'Payment Amount Change',
                        'Payment Date Change',
                        'Payment Collection',
                        'Property Taxes',
                        'Insurance',
                        'Demand Letter',
                        'Foreclosure',
                        'Other',
                      ]"
                      label="Select Service Ticket Type"
                      dense
                      outlined
                    />
                  </v-col>

                  <v-col class="d-flex align-center">
                    <v-btn
                      @click="
                        filtered_service_ticket_type = null;
                        filtered_type = null;
                      "
                    >
                      clear filters
                    </v-btn>
                  </v-col>
                </v-row>

                <v-tabs vertical>
                  <v-tab
                    v-for="objective in filtered_objective_templates"
                    :key="objective.id"
                    class="d-flex align-center justify-start"
                  >
                    {{ objective.subject }}
                  </v-tab>

                  <v-tab-item
                    v-for="objective in filtered_objective_templates"
                    :key="objective.id"
                  >
                    <v-card flat style="background-color: #111">
                      <v-card-text>
                        <v-simple-table>
                          <thead>
                            <tr>
                              <th class="white--text text-center" :width="200">
                                FIELDS
                              </th>
                              <th class="white--text text-center">DETAILS</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td>SUBJECT</td>
                              <td>{{ objective.subject }}</td>
                            </tr>

                            <tr>
                              <td>DESCRIPTION</td>
                              <td>{{ objective.description }}</td>
                            </tr>

                            <tr>
                              <td>TYPE</td>
                              <td>
                                {{
                                  objective.type === "Service Ticket"
                                    ? `${objective.type} -  ${objective.service_ticket_type}`
                                    : objective.type
                                }}

                                {{
                                  objective.service_ticket_type === "Other"
                                    ? `- ${objective.specified_service_ticket_type}`
                                    : ""
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </v-card-text>

                      <v-card-text>
                        <v-row>
                          <v-col class="task-style d-flex">
                            <h2
                              v-if="objective.obj_tsk_templs.length === 0"
                              class="grey--text"
                            >
                              ( No task yet )
                            </h2>
                            <v-data-table
                              :headers="task_header"
                              :items="objective.obj_tsk_templs"
                              :items-per-page="-1"
                            >
                              <template v-slot:item.description="{ item }">
                                <div class="text-wrap">
                                  {{ item.description }}
                                </div>
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col>
                            <v-btn @click="pick_objective(objective)">
                              <v-icon>mdi-gesture-tap</v-icon> Select this
                              template
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";

export default {
  props: ["type"],

  data: () => ({
    pageIsLoading: true,
    formIsValid: false,
    formIsSubmitting: false,
    formIsSubmitted: false,
    formIsError: false,

    template_dialog: false,
    objective_templates: [],
    filtered_type: null,
    filtered_service_ticket_type: null,

    stageName: "",
    description: "",

    assignees: [],

    kbn_stg_objs: [],

    stageData: [],

    components: [],

    rules: { required: (value) => !!value || "Required." },

    task_header: [
      { text: "Subject", value: "subject" },
      { text: "Description", value: "description" },
      { text: "", value: "actions", sortable: false },
    ],
  }),

  computed: {
    sortedObjectiveFields() {
      const res = [...this.kbn_stg_objs];

      res.sort((a, b) => a.order - b.order);

      return res;
    },

    filtered_objective_templates() {
      if (this.filtered_type === null) {
        return this.objective_templates;
      }

      if (this.filtered_type === "Service Ticket") {
        if (this.filtered_service_ticket_type) {
          return this.objective_templates.filter(
            (template) =>
              template.type === this.filtered_type &&
              template.service_ticket_type === this.filtered_service_ticket_type
          );
        } else {
          return this.objective_templates.filter(
            (template) => template.type === this.filtered_type
          );
        }
      }

      return this.objective_templates.filter(
        (template) => template.type === this.filtered_type
      );
    },
  },

  methods: {
    pick_objective(objective) {
      let new_order = 0;

      if (this.kbn_stg_objs.length === 0) {
        new_order = new_order + 1;
      } else {
        new_order = this.kbn_stg_objs.reduce((maxOrder, obj) => {
          return Math.max(maxOrder, obj.order);
        }, 0);

        new_order = new_order + 1;
      }

      objective.obj_tsk_templs = objective.obj_tsk_templs.map((obj) => {
        const { id, ...rest } = obj;
        return { ...rest, kbn_stg_hlps: [] };
      });

      this.kbn_stg_objs.push({
        subject: objective.subject,
        description: objective.description,
        day_limit: objective.day_limit,
        order: new_order,
        type: objective.type,
        service_ticket_type: objective.service_ticket_type,
        specified_service_ticket_type: objective.specified_service_ticket_type,

        kbn_stg_tsks: objective.obj_tsk_templs,
      });

      this.template_dialog = false;
    },

    addObjective() {
      let new_order = 0;

      if (this.kbn_stg_objs.length === 0) {
        new_order = new_order + 1;
      } else {
        new_order = this.kbn_stg_objs.reduce((maxOrder, obj) => {
          return Math.max(maxOrder, obj.order);
        }, 0);

        new_order = new_order + 1;
      }

      this.kbn_stg_objs.push({
        subject: "",
        description: "",
        day_limit: null,
        order: new_order,
        type: null,
        service_ticket_type: null,
        specified_service_ticket_type: null,

        kbn_stg_tsks: [],
      });
    },

    addTask(index) {
      this.sortedObjectiveFields[index].kbn_stg_tsks.push({
        subject: "",
        description: "",
        day_limit: null,
        order:
          this.get_highest_order(
            this.sortedObjectiveFields[index].kbn_stg_tsks
          ) + 1,
        selected_assignees: [],

        kbn_stg_hlps: [],
      });
    },

    addHelper(objective_title, task_title) {
      const objective_index = this.sortedObjectiveFields.findIndex(
        (el) => el.subject === objective_title
      );

      const task_index = this.sortedObjectiveFields[
        objective_index
      ].kbn_stg_tsks.findIndex((el) => el.subject === task_title);

      this.sortedObjectiveFields[objective_index].kbn_stg_tsks[
        task_index
      ].kbn_stg_hlps.push({
        subject: "",
        description: "",
        component: null,
      });
    },

    get_highest_order(objectives) {
      if (objectives.length === 0) return 0;

      const highestOrder = objectives.reduce((maxOrder, task) => {
        return Math.max(maxOrder, task.order);
      }, 0);

      return highestOrder;
    },

    async submit() {
      try {
        this.formIsSubmitting = true;

        const res = await API().post("api/kanban/create_stage", {
          new_stage: this.stageName,
          new_description: this.description,
          ordinal_number: this.stageData.length + 1,
          type: this.type,
          kbn_stg_obj: this.sortedObjectiveFields,
        });
        this.formIsSubmitted = true;

        console.log(res);

        setTimeout(() => {
          this.formIsSubmitted = false;
          this.formIsSubmitting = false;
        }, 2000);

        setTimeout(() => {
          this.$router.push({
            path: `/dashboard/admin/kanban/manage_stages/${this.type}`,
          });
        }, 3000);
      } catch (err) {
        console.log(err);
        this.formIsError = true;
        setTimeout(() => {
          this.formIsError = false;
        }, 2000);

        this.formIsSubmitting = false;
      }
    },

    async getStages() {
      try {
        switch (this.type) {
          case "Deal": {
            const res_deal = await API().get(`api/kanban/get_kanban_deal_data`);

            this.stageData = res_deal.data;

            const res_users = await API().get("api/kanban/get_internal_users");

            [this.assignees] = [res_users.data];

            this.pageIsLoading = false;
            break;
          }

          case "Lender on boarding": {
            const res_lender = await API().get(
              `api/kanban/get_kanban_lender_data`
            );
            this.stageData = res_lender.data;

            const res_users = await API().get("api/kanban/get_internal_users");

            [this.assignees] = [res_users.data];

            this.pageIsLoading = false;
            break;
          }

          default: {
            console.log("type is not valid");
          }
        }

        // get components
        const res_components = await API().get("api/form/read_forms");

        this.components = res_components.data.map((el) => {
          return {
            text: el.subject,
            value: el.unique_id,
          };
        });

        const obj_templs = await API().get(
          "api/kanban/get_objective_templates"
        );

        this.objective_templates = obj_templs.data;
      } catch (err) {
        console.log(err);
        this.formIsError = true;
        setTimeout(() => {
          this.formIsError = false;
        }, 2000);
      }
    },
  },

  mounted() {
    this.getStages();
    this.pageIsLoading = false;
  },
};
</script>
